import "core-js/modules/es.array.push.js";
import { reactive, toRefs, watch } from 'vue';
import Eldialog from "@/components/Eldialog/index.vue";
import { ElMessageBox } from "element-plus";
import API from "@/api/attendance";
import dayjs from "dayjs";
import { useMessage } from "@/hooks/web/useMessage";
export default {
  components: {
    Eldialog
  },
  emits: ['handleSuccess'],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const makeRange = (start, end) => {
      let result = [];
      for (let i = start; i <= end; i++) {
        result.push(i);
      }
      return result;
    };
    const refData = reactive({
      weekIsAllDay: true,
      workIsAllDay: true,
      workFlag: true,
      weekFlag: true,
      isDefault: false,
      title: '规则设置',
      visible: false,
      attendanceId: null,
      deviceId: null,
      hasRule: 0,
      morningTimeStart: new Date(2016, 9, 10, 7),
      morningTimeEnd: new Date(2016, 9, 10, 8),
      typeOptions: [{
        value: 1,
        radio: true,
        img: require('@/assets/images/attendance/header.png'),
        title: '无线感应考勤'
      }, {
        value: 2,
        radio: false,
        img: require('@/assets/images/attendance/idCard.png'),
        title: '刷卡考勤'
      }, {
        value: 3,
        radio: false,
        img: require('@/assets/images/attendance/wifi.png'),
        title: 'WIFI考勤'
      }],
      radio: false,
      type: 1,
      wordday: [],
      holiday: [],
      handleType(item) {
        refData.type = item.value;
        item.radio = true;
      },
      disabledSeconds() {
        return makeRange(1, 59);
      },
      defaultRule: {}
    });
    watch(() => refData.visible, val => {
      if (val) {
        init();
        refData.type = 1;
        refData.attendanceId = null;
        refData.deviceId && refData.hasRule ? getByDeviceId(refData.deviceId) : getDefaultRule();
      }
    });
    watch(() => refData.isDefault, val => {
      if (val) {
        refData.defaultRule.hasOwnProperty('rule') && setParams();
      } else {
        init();
      }
    });
    //初始化数据
    const init = () => {
      refData.type = 1;
      refData.workFlag = true;
      refData.weekFlag = true;
      refData.workIsAllDay = true;
      refData.weekIsAllDay = true;
      const YYYY = Number(dayjs().format('YYYY'));
      const MM = Number(dayjs().format('MM'));
      refData.wordday = [{
        value: new Date(YYYY, MM, 13, 7),
        isPeriod: new Date(YYYY, MM, 13, 8),
        status: 1,
        id: null
      }, {
        value: new Date(YYYY, MM, 13, 8),
        status: 2,
        id: null
      }, {
        value: new Date(YYYY, MM, 13, 8),
        status: 3
      }, {
        value: new Date(YYYY, MM, 13, 11),
        isPeriod: new Date(YYYY, MM, 13, 12, 30),
        status: 4,
        id: null
      }, {
        value: new Date(YYYY, MM, 13, 13),
        isPeriod: new Date(YYYY, MM, 13, 14),
        status: 5,
        id: null
      }, {
        value: new Date(YYYY, MM, 13, 14),
        status: 6,
        id: null
      }, {
        value: new Date(YYYY, MM, 13, 14),
        status: 7,
        id: null
      }, {
        value: new Date(YYYY, MM, 13, 21),
        isPeriod: new Date(YYYY, MM, 13, 22),
        status: 8,
        id: null
      }];
      refData.holiday = [{
        value: new Date(YYYY, MM, 13, 7),
        isPeriod: new Date(YYYY, MM, 13, 8),
        status: 1,
        id: null
      }, {
        value: new Date(YYYY, MM, 13, 8),
        status: 2,
        id: null
      }, {
        value: new Date(YYYY, MM, 13, 8),
        status: 3,
        id: null
      }, {
        value: new Date(YYYY, MM, 13, 11),
        isPeriod: new Date(YYYY, MM, 13, 12, 30),
        status: 4,
        id: null
      }, {
        value: new Date(YYYY, MM, 13, 13),
        isPeriod: new Date(YYYY, MM, 13, 14),
        status: 5,
        id: null
      }, {
        value: new Date(YYYY, MM, 13, 14),
        status: 6,
        id: null
      }, {
        value: new Date(YYYY, MM, 13, 14),
        status: 7,
        id: null
      }, {
        value: new Date(YYYY, MM, 13, 21),
        isPeriod: new Date(YYYY, MM, 13, 22),
        status: 8,
        id: null
      }];
    };
    //时间范围设置
    const disabledMorning = disabled => {
      return makeRange(13, 24);
    };
    //时间范围设置
    const disabledAfternoon = disabled => {
      return makeRange(0, 12);
    };
    //数据处理
    const handleProcess = (arr, name) => {
      refData[name] = arr.map(item => {
        if (item.attendanceTime.indexOf('-') != -1) {
          const timeArr = item.attendanceTime.split('-');
          return {
            value: new Date(dayjs().format(`YYYY-MM-DD ${timeArr[0].substring(0, 2)}:${timeArr[0].substring(3, 5)}`)),
            status: item.attendanceStatus,
            isPeriod: new Date(dayjs().format(`YYYY-MM-DD ${timeArr[1].substring(0, 2)}:${timeArr[1].substring(3, 5)}`)),
            id: item.id
          };
        } else {
          return {
            value: new Date(dayjs().format(`YYYY-MM-DD ${item.attendanceTime}`)),
            status: item.attendanceStatus,
            id: item.id
          };
        }
      });
    };
    //根据id获取规则
    const getByDeviceId = async deviceId => {
      const {
        data,
        code,
        msg
      } = await API.getByDeviceId({
        deviceId
      });
      if (code == 0) {
        refData.type = data.attendanceType;
        refData.workFlag = data.workFlag ? true : false;
        refData.weekFlag = data.weekFlag ? true : false;
        refData.workIsAllDay = data.workIsAllDay ? true : false;
        refData.weekIsAllDay = data.weekIsAllDay ? true : false;
        refData.attendanceId = data.attendanceId;
        data.rule && handleProcess(data.rule, 'wordday');
        data.holidayRule && handleProcess(data.holidayRule, 'holiday');
      } else {
        message.warning(msg);
      }
    };
    //使用默认模板
    const setParams = () => {
      // refData.type = refData.defaultRule.attendanceType; 
      refData.workFlag = refData.defaultRule.workFlag ? true : false;
      refData.weekFlag = refData.defaultRule.weekFlag ? true : false;
      refData.workIsAllDay = refData.defaultRule.workIsAllDay ? true : false;
      refData.weekIsAllDay = refData.defaultRule.weekIsAllDay ? true : false;
      refData.defaultRule.rule && handleProcess(refData.defaultRule.rule, 'wordday');
      refData.defaultRule.holidayRule && handleProcess(refData.defaultRule.holidayRule, 'holiday');
    };
    //获取默认模板
    const getDefaultRule = async () => {
      const {
        data,
        code,
        msg
      } = await API.getDefaultRule();
      if (code != 0) {
        message.warning(msg);
      } else {
        refData.defaultRule = data;
      }
    };
    //设置模板
    const setDefaultRule = async deviceId => {
      const {
        code,
        msg
      } = await API.setDefaultRule({
        deviceId
      });
      message[code == 0 ? 'success' : 'warning'](msg);
    };
    //验证
    const handleError = (key, name) => {
      function getTime(time) {
        return new Date(time).getTime();
      }
      try {
        if (getTime(refData[key][0].value) > getTime(refData[key][0].isPeriod)) throw {
          msg: `${name}上午早上进校开始时间不能大于结束时间`
        };
        if (getTime(refData[key][1].value) > getTime(refData[key][2].value)) throw {
          msg: `${name}上午迟到时间不能大于上午缺勤时间`
        };
        if (getTime(refData[key][2].value) > getTime(refData[key][3].value)) throw {
          msg: `${name}上午缺勤时间不能大于中午离校时间`
        };
        if (getTime(refData[key][3].value) > getTime(refData[key][3].isPeriod)) throw {
          msg: `${name}中午离校开始时间不能大于结束时间`
        };
        if (getTime(refData[key][4].value) > getTime(refData[key][4].isPeriod)) throw {
          msg: `${name}下午进校开始时间不能大于结束时间`
        };
        if (getTime(refData[key][5].value) > getTime(refData[key][6].value)) throw {
          msg: `${name}下午迟到时间不能大于下午缺勤时间`
        };
        if (getTime(refData[key][6].value) > getTime(refData[key][7].value)) throw {
          msg: `${name}下午缺勤时间不能大于晚上离校时间`
        };
        if (getTime(refData[key][7].value) > getTime(refData[key][7].isPeriod)) throw {
          msg: `${name}晚上离校开始时间不能大于结束时间`
        };
        return true;
      } catch ({
        msg
      }) {
        message.warning(msg);
        return false;
      }
    };
    //保存
    const confirm = async () => {
      if (!handleError('wordday', '周一到周五')) return;
      if (!handleError('holiday', '周末')) return;
      const rule = refData.wordday.map(item => ({
        attendanceStatus: item.status,
        attendanceTime: item.hasOwnProperty('isPeriod') ? `${dayjs(item.value).format('HH:mm')}-${dayjs(item.isPeriod).format('HH:mm')}` : dayjs(item.value).format('HH:mm'),
        id: item.id
      }));
      const holidayRule = refData.holiday.map(item => ({
        attendanceStatus: item.status,
        attendanceTime: item.hasOwnProperty('isPeriod') ? `${dayjs(item.value).format('HH:mm')}-${dayjs(item.isPeriod).format('HH:mm')}` : dayjs(item.value).format('HH:mm'),
        id: item.id
      }));
      const params = {
        deviceId: refData.deviceId,
        attendanceType: refData.type,
        rule,
        holidayRule,
        workFlag: refData.workFlag ? 1 : 0,
        weekFlag: refData.weekFlag ? 1 : 0,
        workIsAllDay: refData.workIsAllDay ? 1 : 0,
        weekIsAllDay: refData.weekIsAllDay ? 1 : 0
      };
      const url = !refData.hasRule ? 'addRule' : 'updateRule';
      const {
        code,
        msg
      } = await API[url](params);
      refData.visible = false;
      if (code == 0 && !refData.hasRule) {
        ElMessageBox.confirm(`规则设置成功，是否将当前规则设置为模板`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        }).then(async () => {
          setDefaultRule(params.deviceId);
        }).catch(() => {});
      } else {
        message[code == 0 ? 'success' : 'warning'](code == 0 ? refData.hasRule == 0 ? '设置成功' : '修改成功' : msg);
      }
      emit('handleSuccess', false);
    };
    return {
      ...toRefs(refData),
      getDefaultRule,
      confirm,
      disabledMorning,
      disabledAfternoon
    };
  }
};
import { POSTBody,GET,exportFile } from "../apiFn";
import url from "../url";
import APIType from "./type";

export default {
  /**
   * 考勤分页查询
   */
  getDeviceList:(params:APIType.getDeviceListParam):Promise<APIType.getDeviceListRes>=>GET(url.getDeviceList,params),

  /**
   * 考勤设备新增
   */
  addDevice:(params:APIType.addDeviceParam):Promise<APIType.res>=>POSTBody(url.addDevice,params),

  /**
   * 根据ID查询考勤设备
   */
  getByIdDevice:(params:APIType.getByIdDeviceParam):Promise<APIType.getByIdDeviceRes>=>GET(url.getByIdDevice,params),

  /**
   * 删除考勤设备
   */
  delDevice:(params:APIType.delDeviceParam):Promise<APIType.res>=>GET(url.delDevice,params),

  /**
   * 修改考勤设备
   */
  updateDevice:(params:APIType.addDeviceParam):Promise<APIType.res>=>POSTBody(url.updateDevice,params),

  /**
   * 考勤记录分页查询
   */
  getDeviceRecordList:(params:APIType.getDeviceRecordListParam):Promise<any>=>GET(url.getDeviceRecordList,params),

  /**
   * 导出考勤记录
   */
  exportExcelDeviceRecordList:(params:APIType.getDeviceRecordListParam):void=>exportFile(url.exportExcelDeviceRecordList,params),

  /**
   * 规则新增
   */
  addRule:(params:APIType.addRuleParam):Promise<APIType.res>=>POSTBody(url.addRule,params),

  /**
   * 获取默认的模板规则
   */
  getDefaultRule:():Promise<APIType.getByDeviceIdRes>=>GET(url.getDefaultRule,{}),

  /**
   * 根据考勤机ID查询规则
   */
  getByDeviceId:(params:APIType.getByDeviceIdParam):Promise<APIType.getByDeviceIdRes>=>GET(url.getByDeviceId,params),

  /**
   * 规则修改
   */
  updateRule:(params:APIType.addRuleParam):Promise<APIType.res>=>POSTBody(url.updateRule,params),

  /**
   * 设置默认的模板规则,默认模板绑定账户
   */
  setDefaultRule:(params:APIType.getByDeviceIdParam):Promise<APIType.res>=>GET(url.setDefaultRule,params),

  /**
   * 围栏考勤分页查询
   */
  pathAttPage:(params:APIType.getDeviceRecordListParam):Promise<any>=>GET(url.pathAttPage,params),

  /**
   * 导出围栏考勤excel
   */
  exportPathExcel:(params:APIType.getDeviceRecordListParam):void=>exportFile(url.exportPathExcel,params),

  /**
   * 请假信息导出
   */
  exportLeave:(params:APIType.pageLeaveParam):void=>exportFile(url.exportLeave,params),

  /**
   * 请假信息分页查询
   */
  pageLeave: (params:APIType.pageLeaveParam): Promise<APIType.pageLeaveRes> => 
  GET(url.pageLeave,params),

  /**
   * 请假人数图形数据呈现
   */
  pcLeaveNum: (params:APIType.pageLeaveParam): Promise<APIType.pcLeaveNumRes> => 
  GET(url.pcLeaveNum,params),

  /**
   * 考勤机数据分析
   */
  attDevAnalysis: (params:APIType.getDeviceRecordListParam): Promise<APIType.attDevAnalysisRes> => 
  GET(url.attDevAnalysis,params),

  /**
   * 考勤围栏数据分析
   */
  attPathAnalysis: (params:APIType.getDeviceRecordListParam): Promise<APIType.attDevAnalysisRes> => 
  GET(url.attPathAnalysis,params),
}